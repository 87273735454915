a.btn,
.btn {
  display: inline-block;
  vertical-align: middle;
  height: 42px;
  line-height: 40px;
  text-align: center;
  font-size: 13px;
  background: none;
  border: 1px solid #dddddd;
  position: relative;
  overflow: hidden;
  padding: 0 30px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.top-menu a {
  text-decoration: none;
  color: #fff;
  font-size: 13px;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
}

.active {
  color: var(--active-color) !important;
  border-bottom: 1px dashed var(--active-color) !important;
}

.btn:hover {
  color: #fff !important;
  background: var(--active-color);
  border-color: var(--active-color);
}

.top-menu a:hover {
  color: var(--active-color);
  border-bottom: 1px dashed var(--active-color);
}

.btn:after {
  content: "";
  background: var(--active-color);
  height: 100%;
  width: 0;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transform: translate(-50%, -50%);
}

.top-menu ul a {
  letter-spacing: 1px;
}

/* Style for the hamburger menu */
#menu-toggle:checked + .menu-icon + .menu-content {
  display: block;
  /* Show the menu when the checkbox is checked */
}

.menu-icon span {
  transition: all 0.3s ease;
}

#menu-toggle:checked + .menu-icon span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

#menu-toggle:checked + .menu-icon span:nth-child(2) {
  opacity: 0;
}

#menu-toggle:checked + .menu-icon span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Hide menu content by default */
.menu-content {
  display: none;
}

.menu-content li {
  padding: 8px 0;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 20px;
}

.menu-icon span {
  display: block;
  height: 3px;
  background-color: white;
}
