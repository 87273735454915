* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}


:root {
  --bg-color: #31313a !important;
  --active-color: #f26b38 !important;
  --body-bg-color: #26262d !important;
}

body {
  background-color: var(--body-bg-color);
  color: #fff;
  font-size: 16px;
  line-height: 1.6;
  font-family: 'Roboto Mono', sans-serif;
  overflow-x: hidden;
}

.container {
  background-color: var(--bg-color);
  position: relative !important;
  margin: 30px !important;
  overflow: hidden !important;
  max-width: 100% !important;
  width: auto !important;
}

.glitch-text {
  position: relative;
  color: #fff;
  font-size: inherit;
  line-height: 1.2;
  overflow: hidden;
  display: inline-block;
}

.glitch-text::before,
.glitch-text::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  opacity: 0.8;
  clip-path: inset(0 0 0 0);
}

.glitch-text::before {
  text-shadow: -2px 0 #ff005a;
  animation: glitch-before 1s infinite ease-in-out;
}

.glitch-text::after {
  text-shadow: 2px 0 #00d4ff;
  animation: glitch-after 1s infinite ease-in-out;
}

@keyframes glitch-before {
  0% {
    clip-path: inset(10% 0 0 0);
    transform: translate(0, 0);
  }

  20% {
    clip-path: inset(20% 0 0 0);
    transform: translate(-2px, -2px);
  }

  40% {
    clip-path: inset(30% 0 0 0);
    transform: translate(2px, -1px);
  }

  60% {
    clip-path: inset(10% 0 0 0);
    transform: translate(0, 1px);
  }

  80% {
    clip-path: inset(25% 0 0 0);
    transform: translate(-1px, 2px);
  }

  100% {
    clip-path: inset(10% 0 0 0);
    transform: translate(0, 0);
  }
}

@keyframes glitch-after {
  0% {
    clip-path: inset(0 0 90% 0);
    transform: translate(0, 0);
  }

  20% {
    clip-path: inset(0 0 80% 0);
    transform: translate(2px, 1px);
  }

  40% {
    clip-path: inset(0 0 70% 0);
    transform: translate(-2px, -1px);
  }

  60% {
    clip-path: inset(0 0 90% 0);
    transform: translate(1px, 0);
  }

  80% {
    clip-path: inset(0 0 75% 0);
    transform: translate(-1px, -2px);
  }

  100% {
    clip-path: inset(0 0 90% 0);
    transform: translate(0, 0);
  }
}