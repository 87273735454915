@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .span_left {
    color: rgb(8, 214, 91);
    margin-right: 10px;
  }
  .glass_effect {
    background: rgb(103 100 182 / 19%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .glass_effect_card {
    background: rgba(0, 0, 0, 0.319);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .glass_effect_inner {
    background: rgba(103, 100, 182, 0.007);
    box-shadow: inset 0 4px 30px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
}
